<template>
  <b-row>
    <b-col sm="12" md="6" class="mb-1">
      <my-card title="Daftar Jabatan">
        <template slot="button">
          <b-button v-if="cP(22)" @click="add" variant="primary"><feather-icon icon="PlusIcon"></feather-icon> Tambah Jabatan</b-button>
        </template>
        <template slot="body">
          <data-tables sort-key="1" ref="datatables" endpoint="v1/user_groups" :fields="fields">
            <template v-slot:1="{item}">
              <div class="float-none">
                <button-action v-if="cP(25)" @clicked="changeId(item[1])" size="sm" feather-icon="FolderIcon"></button-action>
                <button-action v-if="cP(23)" @clicked="edit({id: item[1],name: item[0]})" size="sm" feather-icon="EditIcon"></button-action>
                <button-delete v-if="cP(24)" @ondelete="deleteRow" :id="item[1]" :name="item[0]"></button-delete>
              </div>
            </template>
          </data-tables>
        </template>
      </my-card>
      <b-modal ref="formGroup" title="Jabatan Pengguna" hide-footer>
        <form-validation @submit="submitForm">
          <b-row>
            <b-col sm="12">
              <form-input label="Nama Jabatan" :rules="{required: true}" v-model="name"></form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-3" sm="12">
              <button-save :isloading="isloading"></button-save>
            </b-col>
          </b-row>
        </form-validation>
      </b-modal>
    </b-col>
    <b-col sm="12" md="6">
      <v-privilege :id="selectedId" :key="componentKey"></v-privilege>
    </b-col>
  </b-row>
</template>

<script>
import DataTables from '@/my-components/DataTables.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonAction from '@/my-components/ButtonAction.vue'
import ButtonDelete from '@/my-components/ButtonDelete.vue'
import FormInput from '@/my-components/FormInput.vue'
import FormValidation from '@/my-components/FormValidation.vue'
import {BButton} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import vPrivilege from './Privilege.vue'

export default {
  components:{
    BButton,
    DataTables,
    ButtonSave,
    ButtonDelete,
    FormInput,
    FormValidation,
    ButtonAction,
    vPrivilege
  },
  data() {
    return {
      selectedId:null,
      fields: {
        0:{sortable: true, searchable: true, label:'Nama Jabatan'},
        1:{sortable: true, searchable: true, label:''},
      },
      id:null,
      name:'',
      isloading: false,
      componentKey:0
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'usergroups/store',
      dispatchUpdate: 'usergroups/update'
    }),
    changeId(id){
      this.selectedId = id
      this.componentKey++
    },
    async deleteRow(payload){
      try {
        await this.$store.dispatch('usergroups/destroy',payload)
        this.$refs.datatables.refresh()
        this.toastSuccess("OK","Data Berhasil Dihapus!")
      } catch (error) {
        this.handleError(error)
      }
    },
    async submitForm(){
      const {name,id} = this
      let params = {name,id}

      try {
        if(!this.id) await this.dispatchStore(params);
        else await this.dispatchUpdate(params);
        this.$refs.datatables.refresh()
        this.$refs.formGroup.hide()
        this.toastSuccess("OK","Data Berhasil Disimpan!")
      } catch (error) {
        this.handleError(error)
      }
    },
    add(){
      this.id = null
      this.name = ''

      this.$refs.formGroup.show()
    },
    edit({id,name}){
      this.id = id
      this.name = name
      this.$refs.formGroup.show()
    }
  }
}
</script>

<style>

</style>