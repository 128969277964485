<template>
  <my-card v-if="id" :title="`Privilege Jabatan (${parent.name})`">
    <template slot="body">
      <form-validation @submit="submitForm">
        <tree :keyboard-navigation="false" :class="['my-tree',{'tree-dark': skin=='dark'}]" ref="tree" :options="treeOptions"></tree>
        <button-save class="mt-3" :isloading="isloading"></button-save>
      </form-validation>
    </template>
  </my-card>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import ButtonSave from '@/my-components/ButtonSave'
import FormValidation from '@/my-components/FormValidation'
import _ from 'lodash'
import LiquorTree from 'liquor-tree'
Vue.use(LiquorTree)

export default {
  props:{
    id:{
      required: false,
      default:null
    }
  },
  components:{
    ButtonSave,
    FormValidation
  },
  computed:{
    skin(){
      return this.$store.state.appConfig.layout.skin
    }
  },
  data(){
    return {
      parent:{
        name:''
      },
      selected:[],
      isloading: false,
      treeOptions:{
        checkbox: true,
        fetchData: () => this.getPrivilege()
      }
    }
  },
  methods:{
    ...mapActions({
      dispatchGetPrivilege: 'usergroups/getPrivilege',
      dispatchStore: 'usergroups/storePrivilege',
      dispatchShowParent: 'usergroups/show',
    }),
    async getParentData(){
      if(!this.id) return Promise.reject({message:'No id assidgned'});
      const data = await this.dispatchShowParent(this.id)
      this.parent = data
      return Promise.resolve(data)
    },
    getNestedChildren(arr, parent) {
      let out = []
      for (let i in arr) {
        if (arr[i].parent == parent) {
          let children = this.getNestedChildren(arr, arr[i].id)

          if (children.length) {
            arr[i].children = children
          }
          out.push(arr[i])
        }
      }
      return out
    },
    async getPrivilege(){
      if(!this.id) return Promise.reject({message:'No id assidgned'});
      try {
        const {previlage,def,cek} = await this.dispatchGetPrivilege(this.id)
        const mapped = previlage.map((e) => {
          return {
            id: parseInt(e.id),
            text: e.label,
            parent: e.parent,
            state: {
              checked: cek.includes(e.id),
              expanded: def.includes(parseInt(e.id)),
            }
          }
        })
        const options = this.getNestedChildren(mapped,0)

        return Promise.resolve(options)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async submitForm(){
      this.isloading = true
      const raw = this.$refs.tree.checked()
      const checked = raw.map(e => e.id)
      let intermediate = []
      for (let i = 0; i < raw.length; i++) {
        const e = raw[i];
        if(e.parent) {
          intermediate.push(e.parent.id)
          if(e.parent.parent){
            intermediate.push(e.parent.parent.id)
            if(e.parent.parent.parent){
              intermediate.push(e.parent.parent.parent.id)
              if(e.parent.parent.parent.parent){
                intermediate.push(e.parent.parent.parent.parent.id)
                if(e.parent.parent.parent.parent.parent) intermediate.push(e.parent.parent.parent.parent.parent.id)
              }
            }
          }
        }
      }
      const parents = _.uniq(intermediate)
      const rawWithParent = [...checked,...parents]
      const params = {
        id: this.id,
        data: [rawWithParent,parents],
        name: this.parent.name
      }
      try {
        await this.dispatchStore(params)
        this.toastSuccess("Privilege Berhasil Disimpan!")
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    }
  },
  mounted(){
    this.getParentData()
  }
}
</script>

<style lang="scss">
  .my-tree {
    .tree-checkbox {
      width: 15px;
      height: 15px;
    }
    .tree-checkbox.checked, .tree-checkbox.indeterminate {
      border-color: #8479fb;
      background-color: #8479fb;
    }
    .tree-checkbox.checked:after {
      left: 4px;
      top: 2px;
      height: 4px;
      width: 3px;
    }
  }
  .tree-dark {
    .tree-checkbox {
      background: #283046;
    }
    .tree-anchor {
      color:#c6c8cd;
    }
    .tree-node:not(.selected)>.tree-content:hover {
      background: #161d31;
    }
    .tree-arrow.has-child:after {
      border-color: #c6c8cd;
    }
    .tree-node.selected > .tree-content{
      background-color: #161d31;
    }
  }
</style>